import styled, { css, keyframes } from "styled-components";

import { Transition, config } from "../../config";
import { useTranslation } from "react-i18next";
import React, { lazy, useState, useEffect, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  selectuserProfile,
  actionClickSound,
} from "../../Reducers/userProfileSlice";
import { selectPopup, actionPopup } from "../../Reducers/popupSlice";
import RedDot from "./redDot";
import { SocketContext } from "../../context/socket";
// import './style.css';
import {
  active1,
  active2,
  maxDailyTurnOver,
} from "../../Reducers/dailyRewardSlice";
import { Howl } from "howler";
import { rubberBand, pulse } from "react-animations";
import AlertMessage from "../Dialog/AlertMessage";
import GuestPopup from "../GuestTelPopup";

import breakpoints from "../../LandingPageComponents/breakpoints";
import { actionSpinixShopMenu } from "../../Reducers/spinixShopMenuSlice";
import { selectMission } from "../../Reducers/missionSlice";
import { selectRanking } from "../../Reducers/rankingSlice";

const TreasurePop = lazy(() => import("../LuckyTicket"));

const Promotions = lazy(() => import("../Promotion"));
const Ranking = lazy(() => import("../Ranking"));
const Mission = lazy(() => import("../Mission"));
const DiamondExchange = lazy(() => import("../DiamondExchange"));
// const DepositWithdrawal = lazy(() => import('../DepositWithdrawal'));
const DailyRewardPopup = lazy(() => import("../DailyReward"));
const Email = lazy(() => import("../Email"));
const Minigame = lazy(() => import("../MiniGame.js"));
const Event = lazy(() => import("../Event"));
const TutorialAnimation = keyframes`${rubberBand}`;
const EventAnimation = keyframes`${pulse}`;
const DepositWithdrawal = lazy(() => import("../DepositWithdrawal/Deposit"));
const UserProfile = lazy(() => import("../UserProfile"));
const Login = lazy(() => import("../Login"));
const GuestDetect = lazy(() => import("../Register/GuestDetect"));

const BG = styled.div`
  user-select: none;
  position: absolute;
  width: 100vw;
  height: 13.75vw; // FixHeight
  bottom: 0%;
  z-index: 10;
  display: flex;
  align-items: flex-end;
`;

const ShadeBG = styled.div`
  width: 77vw;
  height: 6.5vw;
  /* height: ${() =>
    window.appHeight() / window.innerWidth > config.verticalSizeiphone
      ? "11vw"
      : "6.5vw"}; */
  background: linear-gradient(180deg, rgb(0, 0, 0, 0) 0%, #000 67%);
  /* margin-bottom: ${() =>
    window.appHeight() / window.innerWidth > config.verticalSizeiphone
      ? "16%"
      : "12%"}; */
  margin-bottom: 16%;
  pointer-events: none;
  position: absolute;
  right: 0%;
  //Portrait
  @media (orientation: portrait) {
    height: 11vw;
    margin-bottom: 12%;
  }
`;

const Box = styled.div`
  user-select: none;
  pointer-events: all;
  position: absolute;
  width: 100%;
  height: 90%;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  z-index: 10;
  //Portrait
  @media (orientation: portrait) {
    top: 12vw;
    left: auto;
  }
  //iPad
  @media only screen and (min-width: 481px) and (max-width: 1024px) and (orientation: portrait) {
    top: 6vw;
    left: 8vw;
    justify-content: flex-start;
  }
  /* ${() =>
    window.appHeight() / window.innerWidth > config.verticalSizeiphone
      ? css`
          top: 12vw;
          left: auto;
        `
      : window.appHeight() > window.innerWidth &&
        css`
          top: 6vw;
          left: 8vw;
          justify-content: flex-start;
        `}; */
`;

const AnimsBox = styled.div`
  user-select: none;
  position: relative;
  width: 100%;
  display: flex;
  //Portrait
  @media (orientation: portrait) {
    left: auto;
    top: 12vw;
  }
  //iPad
  @media only screen and (min-width: 481px) and (max-width: 1024px) and (orientation: portrait) {
    justify-content: flex-start;
    top: 6vw;
  }
  /* ${() =>
    window.appHeight() / window.innerWidth > config.verticalSizeiphone
      ? css`
          left: auto;
          top: 12vw;
        `
      : window.appHeight() > window.innerWidth &&
        css`
          justify-content: flex-start;
          top: 6vw;
        `} */
`;

const RankingBox = styled.div`
  user-select: none;
  cursor: pointer;
  width: 10%;
  height: 80%;
  margin-top: 1.5%;
  margin-left: 1.8%;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  position: relative;
  z-index: 1;
  //Portrait
  @media (orientation: portrait) {
    height: 100%;
    transform: scale(1.3);
    margin-left: 4vw;
    width: 12vw;
    text-align: center;
    position: absolute;
    left: 0vw;
  }
  //iPad
  @media only screen and (min-width: 481px) and (max-width: 1024px) and (orientation: portrait) {
    height: 77%;
    transform: scale(1.3);
    margin-left: 4.6vw;
    width: 11vw;
    text-align: center;
  }
  /* ${() =>
    window.appHeight() / window.innerWidth > config.verticalSizeiphone
      ? css`
          height: 100%;
          transform: scale(1.3);
          margin-left: 4vw;
          width: 12vw;
          text-align: center;
          position: absolute;
          left: 0vw;
        `
      : window.appHeight() > window.innerWidth &&
        css`
          height: 77%;
          transform: scale(1.3);
          margin-left: 4.6vw;
          width: 11vw;
          text-align: center;
        `} */
`;

const MissionBox = styled.div`
  user-select: none;
  cursor: pointer;
  width: 10%;
  height: 80%;
  margin-top: 1.5%;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  position: relative;
  //Portrait
  @media (orientation: portrait) {
    height: 100%;
    transform: scale(1.3);
    margin-left: 5vw;
    width: 13vw;
    text-align: center;
    left: 15.511vw;
    position: absolute;
  }
  //iPad
  @media only screen and (min-width: 481px) and (max-width: 1024px) and (orientation: portrait) {
    height: 77%;
    transform: scale(1.3);
    margin-left: 3vw;
    width: 10vw;
    text-align: center;
  }
  /* ${() =>
    window.appHeight() / window.innerWidth > config.verticalSizeiphone
      ? css`
          height: 100%;
          transform: scale(1.3);
          margin-left: 5vw;
          width: 13vw;
          text-align: center;
          left: 15.511vw;
          position: absolute;
        `
      : window.appHeight() > window.innerWidth &&
        css`
          height: 77%;
          transform: scale(1.3);
          margin-left: 3vw;
          width: 10vw;
          text-align: center;
        `} */
`;

const MinigameBox = styled.div`
  user-select: none;
  cursor: pointer;
  width: 10%;
  height: 80%;
  margin-top: 1.5%;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  position: relative;
  //Portrait
  @media (orientation: portrait) {
    height: 100%;
    transform: scale(1.3);
    margin-left: 6vw;
    width: 13vw;
    text-align: center;
    left: 29.13vw;
    position: absolute;
  }
  //iPad
  @media only screen and (min-width: 481px) and (max-width: 1024px) and (orientation: portrait) {
    height: 77%;
    transform: scale(1.3);
    margin-left: 2.4vw;
    width: 9vw;
    text-align: center;
    /* background: rgba(233,233,233,0.5); */
  }
  /* ${() =>
    window.appHeight() / window.innerWidth > config.verticalSizeiphone
      ? css`
          height: 100%;
          transform: scale(1.3);
          margin-left: 6vw;
          width: 13vw;
          text-align: center;
          left: 29.13vw;
          position: absolute;
        `
      : window.appHeight() > window.innerWidth &&
        css`
          height: 77%;
          transform: scale(1.3);
          margin-left: 3.5vw;
          width: 9vw;
          text-align: center;
        `} */
`;

const DailyBox = styled.div`
  user-select: none;
  pointer-events: all;
  cursor: pointer;
  width: 18%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  //Portrait
  @media (orientation: portrait) {
    width: 26%;
    transform: scale(1.3);
    position: absolute;
    top: -8vw;
  }
  //iPad
  @media only screen and (min-width: 481px) and (max-width: 1024px) and (orientation: portrait) {
    width: 20vw;
    transform: scale(1.3);
    margin-left: -5vw;
    top: -8vw;
  }
  /* ${() =>
    window.appHeight() / window.innerWidth > config.verticalSizeiphone
      ? css`
          width: 26%;
          transform: scale(1.3);
          position: absolute;
          top: -8vw;
        `
      : window.appHeight() > window.innerWidth &&
        css`
          width: 20vw;
          transform: scale(1.3);
          margin-left: -5vw;
          top: -8vw;
        `} */
`;

const PromoBox = styled.div`
  user-select: none;
  cursor: pointer;
  width: 10%;
  height: 80%;
  margin-top: 1.5%;
  margin-left: -0.5%;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  position: relative;
  //Portrait
  @media (orientation: portrait) {
    height: 100%;
    transform: scale(1.3);
    margin-left: 0vw;
    justify-content: center;
    width: 17vw;
    left: 50.02vw;
    position: absolute;
  }
  //iPad
  @media only screen and (min-width: 481px) and (max-width: 1024px) and (orientation: portrait) {
    height: 77%;
    transform: scale(1.3);
    margin-left: -4vw;
    width: 8vw;
    /* background: rgba(233,233,233,0.5); */
  }
  /* ${() =>
    window.appHeight() / window.innerWidth > config.verticalSizeiphone
      ? css`
          height: 100%;
          transform: scale(1.3);
          margin-left: 0vw;
          justify-content: center;
          width: 17vw;
          left: 50.02vw;
          position: absolute;
        `
      : window.appHeight() > window.innerWidth &&
        css`
          height: 77%;
          transform: scale(1.3);
          margin-left: -9.8vw;
          width: 8vw;
        `} */
`;

const MailBox = styled.div`
  user-select: none;
  cursor: pointer;
  width: 12%;
  height: 80%;
  margin-top: 1.5%;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  position: relative;
  margin-left: -1%;
  //Portrait
  @media (orientation: portrait) {
    height: 100%;
    transform: scale(1.3);
    margin-left: 7vw;
    text-align: center;
    width: 15vw;
    left: 60.289vw;
    position: absolute;
  }
  //iPad
  @media only screen and (min-width: 481px) and (max-width: 1024px) and (orientation: portrait) {
    height: 77%;
    transform: scale(1.3);
    margin-left: -2.5vw;
    width: 11vw;
    text-align: center;
    /* left: -1vw; */
    /* background: rgba(233,233,233,0.5); */
  }
  /* ${() =>
    window.appHeight() / window.innerWidth > config.verticalSizeiphone
      ? css`
          height: 100%;
          transform: scale(1.3);
          margin-left: 7vw;
          text-align: center;
          width: 15vw;
          left: 60.289vw;
          position: absolute;
        `
      : window.appHeight() > window.innerWidth &&
        css`
          height: 77%;
          transform: scale(1.3);
          margin-left: 5vw;
          width: 11vw;
          text-align: center;
          left: -1vw;
        `} */
`;

const TutorialArrow = styled.img`
  position: absolute;
  width: 7vw;
  top: -27.5%;
  animation: ${TutorialAnimation} 1.5s linear infinite;
  z-index: 11;
  //Portrait
  @media (orientation: portrait) {
    width: 10vw;
  }
  /* ${() =>
    window.appHeight() / window.innerWidth > config.verticalSizeiphone
      ? css`
          width: 10vw;
        `
      : window.appHeight() > window.innerWidth && css``} */
`;

const TutorialBox = styled.div`
  pointer-events: none;
  width: 80%;
  height: 80%;
  position: absolute;
  bottom: 0%;
  font-size: 24px;
  border: 0.5vw solid;
  resize: both;
  border-image: url("data:image/svg+xml;charset=utf-8,%3Csvg width='100' height='100' viewBox='0 0 100 100' fill='none' xmlns='http://www.w3.org/2000/svg'%3E %3Cstyle%3Epath%7Banimation:stroke 1.5s infinite linear%3B%7D%40keyframes stroke%7Bto%7Bstroke-dashoffset:776%3B%7D%7D%3C/style%3E%3ClinearGradient id='g' x1='180%25' y1='0%25' x2='80%25' y2='100%25'%3E%3Cstop offset='0%25' stop-color='%23d02d07' /%3E%3Cstop offset='25%25' stop-color='%23ff5400' /%3E%3Cstop offset='50%25' stop-color='%23ff7e00' /%3E%3Cstop offset='100%25' stop-color='%23ffea00' /%3E%3C/linearGradient%3E %3Cpath d='M1.5 1.5 l97 0l0 97l-97 0 l0 -97' stroke-linecap='square' stroke='url(%23g)' stroke-width='20' stroke-dasharray='193.6'/%3E %3C/svg%3E")
    1;
  //Portrait
  @media (orientation: portrait) {
    width: 88%;
    height: 93%;
  }
  /* ${() =>
    window.appHeight() / window.innerWidth > config.verticalSizeiphone
      ? css`
          width: 88%;
          height: 93%;
        `
      : window.appHeight() > window.innerWidth &&
        `

 `} */
`;

const tutorialinfo = keyframes`
    0% { border-left: solid 0.4vw #FF691F; }
    100% {border-left: solid 0.4vw yellow;}
`;
const tutorialinfomobile = keyframes`
    0% { border-left: solid 1vw #FF691F; }
    100% {border-left: solid 1vw yellow;}
`;
const TutorialInfo = styled.div`
  background-color: red;
  // max-width: 15vw;
  // min-width: 4vw;
  max-width: 140%;
  min-width: 110%;
  color: white;
  border-radius: 1vw;
  position: absolute;
  padding: 1vw 1.6vw;
  line-height: 1.4vw;
  bottom: 115%;
  z-index: 10;
  text-align: left;
  box-shadow: 0px 0px 50px 20px rgba(0, 0, 0, 0.8);
  background: #302e3c;
  box-shadow: inset 0vw 0vw 0.8vw 0.2vw #ff691f,
    inset 0px 0px 4vw rgba(255, 133, 66, 0.63);
  border: solid 0.2vw orange;

  /* background: #192756;
  box-shadow: inset 0vw 0vw 0.8vw 0.2vw #5dd8ff,
    inset 0px 0px 4vw rgba(66, 255, 232, 0.63);
  border: solid 0.2vw #bdefff; */

  h1 {
    font-size: 1.5vw;
    font-weight: 400;
    border-left: solid 0.4vw #ff691f;
    padding-left: 0.5vw;
    margin-bottom: 0.5vw;
    line-height: 1.9vw;
    animation-name: ${tutorialinfo};
    animation-duration: 0.2s;
    animation-timing-function: linear;
    animation-direction: alternate;
    animation-iteration-count: infinite;
  }
  p {
    font-size: 1.2vw;
    color: rgba(255, 255, 255, 0.7);
    margin-left: 1vw;
  }
  //Portrait
  @media (orientation: portrait) {
    h1 {
      font-size: 3vw;
      line-height: 3vw;
      animation-name: ${tutorialinfomobile};
    }
    p {
      line-height: 2.5vw;
      font-size: 1.8vw;
    }
  }
  /* ${() =>
    window.appHeight() / window.innerWidth > config.verticalSizeiphone
      ? css`
          h1 {
            font-size: 3vw;
            line-height: 3vw;
            animation-name: ${tutorialinfomobile};
          }
          p {
            line-height: 2.5vw;
            font-size: 1.8vw;
          }
        `
      : window.appHeight() > window.innerWidth &&
        css`
          h1 {
            font-size: 3vw;
            line-height: 3vw;
            animation-name: ${tutorialinfomobile};
          }
          p {
            line-height: 2.5vw;
            font-size: 1.8vw;
          }
        `} */
`;

const SupBox = styled.div`
  user-select: none;
  cursor: pointer;
  width: 10%;
  height: 80%;
  margin-top: 1.5%;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  position: relative;
  z-index: 2;
  //Portrait
  @media (orientation: portrait) {
    height: 100%;
    transform: scale(1.3);
    margin-left: 7vw;
    width: 11vw;
    text-align: center;
    left: 77.68vw;
    position: absolute;
  }
  //iPad
  @media only screen and (min-width: 481px) and (max-width: 1024px) and (orientation: portrait) {
    height: 77%;
    transform: scale(1.3);
    margin-left: -4vw;
    width: 9vw;
    text-align: center;
    /* background: rgba(233,233,233,0.6); */
  }
  /* ${() =>
    window.appHeight() / window.innerWidth > config.verticalSizeiphone
      ? css`
          height: 100%;
          transform: scale(1.3);
          margin-left: 7vw;
          width: 11vw;
          text-align: center;
          left: 77.68vw;
          position: absolute;
        `
      : window.appHeight() > window.innerWidth &&
        css`
          height: 77%;
          transform: scale(1.3);
          margin-left: 4vw;
          width: 9vw;
          text-align: center;
        `} */
`;

const Text = styled.div`
  user-select: none;
  color: white;
  font-size: 1.5vw;
  position: relative;
  text-align: center;
  //Portrait
  @media (orientation: portrait) {
    font-size: 1.8vw;
    width: 100%;
  }
  //iPad
  @media only screen and (min-width: 481px) and (max-width: 1024px) and (orientation: portrait) {
    font-size: 1.3vw;
  }
  /* ${() =>
    window.appHeight() / window.innerWidth > config.verticalSizeiphone
      ? css`
          font-size: 1.8vw;
          width: 100%;
        `
      : window.appHeight() > window.innerWidth &&
        css`
          font-size: 1.3vw;
        `} */
`;
const Container = styled.div`
  pointer-events: none;
  // background-color: red;
  width: 100%;
  // height: 14vw;
  // height: 116px;
  height: 12.5vw;
  position: absolute;
  bottom: 0vw;
  z-index: 0;
  color: white;
  background-image: url(../../${config.theme}/newasset/nav_bot_panel_bg.png);
  background-size: contain;
  background-repeat: repeat-x;
  background-position: center bottom;
  // background-position: 0 1vw;
  opacity: 1;
  @media ${breakpoints.device.line} {
    background-position: center 3vw;
  }
  //Portrait
  @media (orientation: portrait) {
    height: 26.4vw;
    background-size: 292%;
    background-position: center top;
  }
  //iPad
  @media only screen and (min-width: 481px) and (max-width: 1024px) and (orientation: portrait) {
    height: 18.48vw;
    background-size: 223%;
    background-position: center top;
  }
  /* ${() =>
    window.appHeight() / window.innerWidth > config.verticalSizeiphone
      ? css`
          height: 26.4vw;
          background-size: 292%;
          background-position: center top;
        `
      : window.appHeight() > window.innerWidth &&
        css`
          height: 18.48vw;
          background-size: 223%;
          background-position: center top;
        `} */
`;
const animsprop = css`
  animation-timing-function: cubic-bezier(0.29, 0, 0.15, 0.99);
  animation-direction: alternate;
  animation-iteration-count: infinite;
  animation-duration: 0.7s;
`;
const MiddleFrame = styled.div`
  width: fit-content;
  height: fit-content;
  // margin: 0 auto;
  position: absolute;
  /* top: 1.5vw; */
  @media ${breakpoints.device.line} {
    transform: scale(0.7);
    top: 1.5vw;
  }
`;
const NavImgStyle = css`
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center center;
  position: absolute;
`;
// footer icon set ===================================================
const IconProp = css`
  width: 95%;
  height: 95%;
  position: relative;
  background-size: contain;
  background-repeat: no-repeat;
`;
const ShopImg = styled.div`
  ${IconProp}
  background-image: url(../../${config.theme}/footericon/icon_shop.png);
`;
const MissionImg = styled.div`
  ${IconProp}
  background-image: url(../../${config.theme}/footericon/icon_flag.png);
`;
const MinigameImg = styled.div`
  ${IconProp}
  background-image: url(../../${config.theme}/footericon/icon_controller.png);
`;
const PromoImg = styled.div`
  ${IconProp}
  background-image: url(../../${config.theme}/footericon/icon_promo.png);
`;
const RankImg = styled.div`
  ${IconProp}
  background-image: url(../../${config.theme}/footericon/icon_ranking.png);
`;
const MailImg = styled.div`
  ${IconProp}
  background-image: url(../../${config.theme}/footericon/icon_mail.png);
`;
// footer icon set ===================================================
const Shop = styled.div`
  // background: red;
  width: 10vw;
  height: 10vw;
  position: relative;
  margin: 0vw 1vw 1vw 4vw;
  // margin: 10px 5px 5px 20px;
  float: left;
  z-index: 1;
  div {
    margin: 0 auto;
  }
  //Portrait
  @media (orientation: portrait) {
    transform: scale(1.5);
    left: 1vw;
    margin: 0vw 1vw 1vw 4vw;
  }
  //iPad
  @media only screen and (min-width: 481px) and (max-width: 1024px) and (orientation: portrait) {
    transform: scale(1.1);
    left: 9.1232vw;
  }
  /* ${() =>
    window.appHeight() / window.innerWidth > config.verticalSizeiphone
      ? css`
          transform: scale(1.5);
          left: 1vw;
          margin: 0vw 1vw 1vw 4vw;
        `
      : window.appHeight() > window.innerWidth &&
        css`
          transform: scale(1.1);
          left: 9.1232vw;
        `} */
`;

// const signanims = keyframes
//     0% {top: 1.5vw; transform: scale(1.2);}
//     100% {top: 1.1vw; transform: scale(1.3);}
// `;
// const Shop_sign = styled.div`
//   background-image: url(../../${config.theme}/newasset/shop_sign.png);
//   background-size: cover;
//   width: 3.8vw;
//   height: 2.4vw;
//   top: 1.5vw;
//   position: relative;
//   z-index: 5;
//   ${animsprop};
//   // animation-name: ${signanims};
//   @media ${breakpoints.device.line} {
//     // animation-name: ${signanims};
//     margin-top: 2 vw;
//     // มีปัญหาเรื่อง asset เลื่อนแต่แก้ได้ด้วยการ refresh ไปก่อน
//   }
// `;
// const roofanims = keyframes
//     0% {top: 0; transform: scale(0.94);}
//     100% {top: -0.7vw; transform: scaleX(1.06) scaleY(0.9);}
// `;
// const Shop_roof = styled.div`
//   background-image: url(../../${config.theme}/newasset/shop_roof.png);
//   background-size: cover;
//   width: 7.9vw;
//   height: 2.8vw;
//   top: 0vw;
//   position: relative;
//   z-index: 4;
//   // animation-name: ${roofanims};
//   ${animsprop}
// `;
// const housebody = keyframes
//     0% {top: -2vw; transform: scale(0.94);}
//     100% {top: -2.5vw; transform: scale(1);}
// `;
// const Shop_house = styled.div`
//   background-image: url(../../${config.theme}/newasset/shop_base.png);
//   background-size: cover;
//   width: 7.5vw;
//   height: 5.5vw;
//   top: -2vw;
//   right: 0.1vw;
//   position: relative;
//   z-index: 3;
//   ${animsprop};
//   // animation-name: ${housebody};
// `;
// const Shop_bush_main = css`
//   background-image: url(../../${config.theme}/newasset/shop_bush.png);
//   background-size: cover;
//   width: 2.7vw;
//   height: 1.7vw;
//   // top: -3.8vw;
//   bottom: 1vw;
//   position: absolute;
//   z-index: 4;
// `;
// const bushanims = keyframes
//     0% {bottom: 1.2vw; transform: scale(1);}
//     100% {bottom: 1.4vw; transform: scale(1.1);}
// `;
// const Shop_bush = styled.div`
//   ${Shop_bush_main}
//   transform: scaleX(1);
//   right: 0.5vw;
//   ${animsprop};
//   // animation-name: ${bushanims};
// `;
// const bushanims2 = keyframes
//     0% {bottom: 1.2vw; transform: scaleX(-1) scaleY(1);}
//     100% {bottom: 1.4vw; transform: scaleX(-1.1) scaleY(1.1);}
// `;
// const Shop_bush2 = styled.div`
//   ${Shop_bush_main}
//   transform: scaleX(-1);
//   left: 0.5vw;
//   ${animsprop};
//   // animation-name: ${bushanims2};
// `;

// const sticks = keyframes
//     0% { transform: rotate(-4deg); top: 0;}
//     100% {transform: rotate(4deg); top: -0.8vw;}
// `;
// const FlagContainer = styled.div`
//   width: 8vw;
//   height: 8vw;
//   margin: auto;
//   // background: red;
//   // top: 1vw;
//   position: relative;
//   ${animsprop};
//   // animation-name: ${sticks};
//   transform-origin: left bottom;
// `;

const MissionAnims = styled.div`
  // background: green;
  width: 10vw;
  height: 10vw;
  position: relative;
  margin: 1vw 1vw 1vw 1vw;
  float: left;
  z-index: 1;
  //Portrait
  @media (orientation: portrait) {
    transform: scale(1.5);
    margin-left: 5vw;
    left: 2vw;
  }
  //iPad
  @media only screen and (min-width: 481px) and (max-width: 1024px) and (orientation: portrait) {
    transform: scale(1.1);
    margin-left: 5vw;
    left: 6.868vw;
  }
  /* ${() =>
    window.appHeight() / window.innerWidth > config.verticalSizeiphone
      ? css`
          transform: scale(1.5);
          margin-left: 5vw;
          left: 2vw;
        `
      : window.appHeight() > window.innerWidth &&
        css`
          transform: scale(1.1);
          margin-left: 5vw;
          left: 6.868vw;
        `} */
`;
// const Mission_stick = styled.div`
//   background-image: url(../../${config.theme}/newasset/icon_flag_cone.png);
//   background-repeat: no-repeat;
//   background-size: contain;
//   background-position: center center;
//   width: 4vw;
//   height: 8vw;
//   // background-color: blue;
//   position: absolute;
//   margin: 0 auto;
//   left: 0vw;
//   top: 0.8vw;
//   z-index: 2;
// `;
// const flag = keyframes
//     0% {transform: skew(0deg) scaleX(1); top: 2vw;}
//     100% { transform: skew(0deg, 8deg) scaleX(0.8); top:2vw;}
//`;
// const Mission_flag = styled.div`
//   background-image: url(../../${config.theme}/newasset/icon_flag.png);
//   background-repeat: no-repeat;
//   background-size: contain;
//   background-position: center center;
//   width: 6.2vw;
//   height: 5.2vw;
//   position: absolute;
//   margin: auto;
//   z-index: 1;
//   top: 2vw;
//   left: 1.3vw;
//   ${animsprop};
//   // animation-name: ${flag};
//   transform-origin: left center;
// `;

const MinigameAnims = styled.div`
  // background: gray;
  width: 10vw;
  height: 10vw;
  position: relative;
  margin: 0 1vw;
  margin: 1vw 1vw 1vw 1vw;
  float: left;
  z-index: 1;
  //Portrait
  @media (orientation: portrait) {
    transform: scale(1.5);
    margin-left: 5vw;
    left: 0.5vw;
  }
  //iPad
  @media only screen and (min-width: 481px) and (max-width: 1024px) and (orientation: portrait) {
    transform: scale(1.1);
    margin-left: 5vw;
    left: 3.5425vw;
  }
  /* ${() =>
    window.appHeight() / window.innerWidth > config.verticalSizeiphone
      ? css`
          transform: scale(1.5);
          margin-left: 5vw;
          left: 0.5vw;
        `
      : window.appHeight() > window.innerWidth &&
        css`
          transform: scale(1.1);
          margin-left: 5vw;
          left: 3.5425vw;
        `} */
`;
// const minigame = keyframes
//     0% {top: 1.3vw; transform: scaleX(1.1);}
//     5% {top: 0.95vw; transfrom: scaleX(1);}
//     100% {top: 0.2vw; transfrom: scaleX(1);}
// `;
// const Minigame_controller = styled.div`
//   background-image: url(../../${config.theme}/newasset/joystick.png);
//   background-repeat: no-repeat;
//   background-size: contain;
//   width: 8vw;
//   height: 8vw;
//   // background-color: blue;
//   margin: auto auto;
//   top: 1vw;
//   position: relative;
//   ${animsprop};
//   // animation-name: ${minigame};
//   animation-timing-function: ease !important;
// `;

const Daily = styled.div`
  // background: rgba(100,100,100,0.6);
  width: 20vw;
  height: 11vw;
  position: realative;
  margin: 1vw 1vw 1vw 1vw;
  float: left;
  position: relative;
  z-index: 1;
  pointer-events: none;
  //Portrait
  @media (orientation: portrait) {
    transform: scale(2);
    margin-left: -7vw;
    top: -8vw;
  }
  //iPad
  @media only screen and (min-width: 481px) and (max-width: 1024px) and (orientation: portrait) {
    transform: scale(1.5);
    margin-left: -7vw;
    top: -8vw;
  }
  /* ${() =>
    window.appHeight() / window.innerWidth > config.verticalSizeiphone
      ? css`
          transform: scale(2);
          margin-left: -7vw;
          top: -8vw;
        `
      : window.appHeight() > window.innerWidth &&
        css`
          transform: scale(1.5);
          margin-left: -7vw;
          top: -8vw;
        `} */
`;
const sign = keyframes`
    0% { transform: scale(1); bottom: 0; }
    30% {bottom: 0.3vw;}
    100% {transform: scale(1.05); bottom: 0.3vw ;}
`;
const Daily_sign = styled.div`
  background-image: url(../../${config.theme}/newasset/d_front.png);
  ${NavImgStyle}
  width: 20vw;
  height: 6.8vw;
  bottom: 0;
  margin: 0 0 -1vw 0;
  z-index: 3;
  ${animsprop};
  animation-name: ${sign};
  animation-timing-function: ease;
`;
const diamond = keyframes`
    0% {top: 1.6vw; transform: scale(1);}
    50% {transform: scale(1);}
    100% {top: 2.6vw; transform: scale(1.1);}
`;
const Daily_diamond = styled.div`
  background-image: url(../../${config.theme}/newasset/d_gem.png);
  ${NavImgStyle}
  width: 20vw;
  height: 6vw;
  top: 2vw;
  z-index: 2;
  ${animsprop};
  animation-name: ${diamond};
`;
const logo = keyframes`
    0% {top:0vw;}
    100% {top:-0.4vw;}
`;
const Daily_logo = styled.div`
  background-image: url(../../${config.theme}/newasset/d_back.png);
  ${NavImgStyle}
  width: 20vw;
  height: 6.5vw;
  z-index: 1 !important;
  ${animsprop};
  animation-name: ${logo};
`;
const FlareContainer = styled.div``;
const Flareimg = css``;
const Flare1 = styled.div`
  ${Flareimg}
`;
// Promotion icon
const Promotion = styled.div`
  // background: pink;
  width: 10vw;
  height: 10vw;
  position: relative;
  margin: 0vw 1vw 1vw 1vw;
  left: -2.5vw;
  float: left;
  //Portrait
  @media (orientation: portrait) {
    left: -1.2vw;
    transform: scale(1.5);
    margin-left: -6vw;
  }
  //iPad
  @media only screen and (min-width: 481px) and (max-width: 1024px) and (orientation: portrait) {
    transform: scale(1.1);
    margin-left: -6vw;
    left: -1.545vw;
  }
  /* ${() =>
    window.appHeight() / window.innerWidth > config.verticalSizeiphone
      ? css`
          left: -1.2vw;
          transform: scale(1.5);
          margin-left: -6vw;
        `
      : window.appHeight() > window.innerWidth &&
        css`
          transform: scale(1.1);
          margin-left: -6vw;
          left: -1.545vw;
        `} */
`;
// const probox = keyframes
//     0% {top: 3vw; transform: scaleX(1.1);}
//     40% {transform: scaleX(1);}
//     100% {top: 2.2vw; transform: scaleX(1);}
// `;
// const Promotion_box = styled.div`
//   background-image: url(../../${config.theme}/newasset/box.png);
//   ${NavImgStyle}
//   width: 10vw;
//   height: 5.6vw;
//   top: 3vw;
//   ${animsprop};
//   // animation-name: ${probox};
// `;
// const ribbon = keyframes
//     0% {top: 2vw; transform: scaleX(1.2) scaleY(0.9);}
//     40% {transform: scaleX(1) scaleY(1.2);}
//     100% {top: 1.2vw; transform: scaleX(1) scaleY(1.2);}
// `;
// const Promotion_ribbon = styled.div`
//   background-image: url(../../${config.theme}/newasset/bow.png);
//   ${NavImgStyle}
//   width: 10vw;
//   height: 2.2vw;
//   top: 2vw;
//   ${animsprop};
//   // animation-name: ${ribbon};
//   transform-origin: center bottom;
// `;

// Mail icon
const Mail = styled.div`
  // background: orange;
  width: 10vw;
  height: 10vw;
  position: relative;
  margin: 1vw 1vw 1vw 1vw;
  left: -2.5vw;
  float: left;
  z-index: 1;
  //Portrait
  @media (orientation: portrait) {
    left: -1.5vw;
    transform: scale(1.5);
    margin-left: 5vw;
  }
  //iPad
  @media only screen and (min-width: 481px) and (max-width: 1024px) and (orientation: portrait) {
    transform: scale(1.1);
    margin-left: 5vw;
    left: -4.4587vw;
  }
  /* ${() =>
    window.appHeight() / window.innerWidth > config.verticalSizeiphone
      ? css`
          left: -1.5vw;
          transform: scale(1.5);
          margin-left: 5vw;
        `
      : window.appHeight() > window.innerWidth &&
        css`
          transform: scale(1.1);
          margin-left: 5vw;
          left: -4.4587vw;
        `} */
`;
// const mailmain = keyframes
//     0% {top: 2.5vw;}
//     100% {top: 2vw;}
// `;
// const Mail_main = styled.div`
//   background-image: url(../../${config.theme}/newasset/mail_body.png);
//   ${NavImgStyle}
//   width: 10vw;
//   height: 5.3vw;
//   top: 2.5vw;
//   z-index: 0;
//   ${animsprop};
//   // animation-name: ${mailmain};
// `;
// const maillid = keyframes
//     0% {top: 2.5vw; transform: skewX(-10deg);}
//     100% {top: 2vw; transform: skewX(10deg);}
// `;
// const Mail_lid = styled.div`
//   background-image: url(../../${config.theme}/newasset/mail_lid.png);
//   ${NavImgStyle}
//   width: 7.2vw;
//   height: 2.9vw;
//   top: 2.5vw;
//   left: 1.2vw;
//   z-index: 1;
//   ${animsprop};
//   // animation-name: ${maillid};
//   transform-origin: top right;
// `;

// Rank icon
const Rank = styled.div`
  // background: yellow;
  width: 10vw;
  height: 10vw;
  position: relative;
  margin: 0vw 1vw 1vw 1vw;
  left: -1vw;
  float: left;
  z-index: 1;
  //Portrait
  @media (orientation: portrait) {
    transform: scale(1.5);
    margin-left: 5vw;
    left: -1vw;
  }
  //iPad
  @media only screen and (min-width: 481px) and (max-width: 1024px) and (orientation: portrait) {
    transform: scale(1.1);
    margin-left: 0.746vw;
  }
  /* ${() =>
    window.appHeight() / window.innerWidth > config.verticalSizeiphone
      ? css`
          transform: scale(1.5);
          margin-left: 5vw;
          left: -1vw;
        `
      : window.appHeight() > window.innerWidth &&
        css`
          transform: scale(1.1);
          margin-left: 0.746vw;
        `} */
`;

const RankIcon = styled.div`
  // background-image: url("../../${config.theme}/newasset/icon_ranking_blank.png");
  background-image: url(../../${config.theme}/newasset/icon_ranking_coming_soon.png);
  ${NavImgStyle}
  background-position: center bottom!important;
  width: 8vw;
  height: 8vw;
  filter: grayscale();
  left: 0.5vw;
  top: 1vw;
  z-index: 2vw;
  transform-origin: right bottom;
`;

const TextSeason = styled.p`
  font-size: 1.2vw;
  font-weight: 700;
  background: linear-gradient(180deg, #faf49e 24.07%, #ffbf3c 72.22%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  /* text-shadow: 0px 2px 0px #441c00; */
  display: flex;
  align-items: flex-end;
  justify-content: center;
  height: 88%;
`;

const WinkContainer = styled.div`
  width: fit-content;
  height: fit-content;
  position: absolute;
`;
const wink = keyframes`
    0% {transform: scale(0) rotate(0deg);opacity: 1;}
    50% {transform: scale(1) rotate(45deg); opacity: 1;}
    100% {transform: scale(2) rotate(90deg); opacity: 0;}
`;
const winkimg = css`
  background-image: url(../../${config.theme}/newasset/d_wink.png);
  background-size: contain;
  background-position: center center;
  width: 8vw;
  height: 8vw;
  position: absolute;
  z-index: 6;
  mix-blend-mode: screen;
  animation-direction: normal;
  animation-iteration-count: infinite;
  animation-name: ${wink};
  animation-timing-function: normal;
  transform-origin: center center;
  pointer-events: none;
`;

const Wink1 = styled.div`
  ${winkimg};
  top: 1vw;
  left: 12vw;
  animation-duration: 1s;
`;
const Wink2 = styled.div`
  ${winkimg};
  top: 2vw;
  left: 9vw;
  animation-duration: 1.2s;
`;
const Wink3 = styled.div`
  ${winkimg};
  top: 6vw;
  left: 5vw;
  animation-duration: 1.6s;
`;
const Wink4 = styled.div`
  ${winkimg};
  top: -3vw;
  left: 10vw;
  animation-duration: 1.5s;
`;
const Wink5 = styled.div`
  ${winkimg};
  top: -1vw;
  left: 1vw;
  animation-duration: 0.9s;
`;
const Wink6 = styled.div`
  ${winkimg};
  top: 1vw;
  left: -1vw;
  animation-duration: 1.1s;
`;
// re-check on this ==============

const EventTag = styled.div`
  pointer-events: none;
  font-size: 1.2vw;
  width: 65%;
  height: 20%;
  position: absolute;
  background-color: #2097f6;
  bottom: 24%;
  border-radius: 0.5vw;
  animation: ${EventAnimation} 0.5s linear infinite;
  display: flex;
  justify-content: center;
  align-items: center;
  white-space: nowrap;
`;

const PromotionBadge = styled.div`
  width: max-content;
  height: 23%;
  padding: 0 1.3vw 0 1.3vw;
  border-radius: 2vw;
  background-color: #2097f6;
  position: absolute;
  z-index: 1;
  bottom: 22.5%;
  font-size: 1.2vw;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
`;

export default function Footer(props) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const socket = useContext(SocketContext);
  const [treasurebox, setTreasurebox] = useState(false);

  const [ranking, setRanking] = useState(false);

  const [dailyReward, setDailyReward] = useState(false);

  const firstItem = useSelector(active1);
  const secondItem = useSelector(active2);
  const thirdItem = useSelector(maxDailyTurnOver);
  const profile = useSelector(selectuserProfile);
  const popupStatus = useSelector(selectPopup);
  const [dailyText, setDailyText] = useState("diamonds");
  const [emitError, setEmitError] = useState({ status: false, message: "" });
  const [click, setClick] = useState({
    diamondexchange: false,
    mission: false,
    wheel: false,
    treasure: false,
    promo: false,
    mail: false,
    rankink: false,
  });
  const [guestPopup, setGuestPopup] = useState(false);
  const mission = useSelector(selectMission);
  const rank = useSelector(selectRanking);

  const missionEvent = mission.filter(
    (ms) => ms.mission_group == 5 || ms.mission_group == 6
  ).length;

  useEffect(() => {
    try {
      let dailyArray = [
        profile.getreward1,
        profile.getreward2,
        profile.getreward3,
      ];
      let dailyArrayText = [firstItem.text, secondItem.text, thirdItem.text];
      for (let i = 0; i < 3; i++) {
        if (dailyArray[i] === "F" || dailyArray[i] === "T") {
          setDailyText(dailyArrayText[i]);
        }
      }
    } catch (error) {
      setEmitError({ status: true, message: error.message });
      setTimeout(() => {
        setEmitError({ status: false });
      }, 3000);
    }
  }, [profile.dailyreward]);

  const guestClick = () => {
    setGuestPopup(true);
  };

  // const TutorialClick = (e) => {
  //   try {
  //     socket.emit(
  //       "updateTutorial",
  //       {
  //         no: e,
  //       },
  //       (body) => {
  //         // console.log(body);
  //         if (typeof body != "undefined") {
  //           if (body.status) {
  //           } else if (body.status === false) {
  //             setEmitError({ status: true, message: body.errorCode });
  //             setTimeout(() => {
  //               setEmitError({ status: false });
  //             }, 3000);
  //           }
  //         } else {
  //         }
  //       }
  //     );
  //   } catch (error) {
  //     setEmitError({ status: true, message: error.message });
  //     setTimeout(() => {
  //       setEmitError({ status: false });
  //     }, 3000);
  //   }
  // };

  return (
    <>
      <BG>
        {window.appHeight() > window.innerWidth ? <ShadeBG /> : ""}
        <Container click={click}>
          <MiddleFrame>
            {/* {window.appHeight() > window.innerWidth ? (
              <Profile>
                <Logo level={profile.level} />
                <Deposit />
              </Profile>
            ) : (
              ""
            )} */}

            <Box
            // style={{
            //   bottom:
            //     window.appHeight() / window.innerWidth >
            //     config.verticalSizeiphone
            //       ? `calc(${window.appHeight()}px - 31.6vw)`
            //       : window.appHeight() > window.innerWidth
            //       ? `calc(${window.appHeight() * 1.03}px - 31.6vw)`
            //       : "",
            // }}
            >
              <RankingBox
                onClick={() => {
                  try {
                    socket.emit(
                      "getbilog",
                      {
                        biname: "shop_open",
                        open_from: "Shop icon",
                      },
                      (data) => {
                        console.log(data);
                      }
                    );
                  } catch (error) {
                    console.log(error);
                  }
                  // setDiamondexchange(true)
                  if (profile.isguest && profile.isTelGuest === false) {
                    guestClick();
                  } else {
                    dispatch(actionPopup({ diamondExchange: true }));
                    // dispatch(actionPopup({ diamondShop: true }));
                    // setClick({ ...click, diamondexchange: true });
                    // setTimeout(() => {
                    //   setClick({ ...click, diamondexchange: false });
                    // }, 333);
                    dispatch(actionClickSound());
                  }
                }}
              >
                {profile.timeLimit.filter(
                  (item) => Object.keys(item).length > 0
                ).length > 0 ? (
                  <PromotionBadge>{t("Promotion")}</PromotionBadge>
                ) : (
                  ""
                )}
                <Text>{t("Shop")}</Text>
                {profile.tutorial === 8 ? (
                  <>
                    <TutorialArrow
                      src={
                        // window.appHeight() > window.innerWidth
                        //   ? "../" + config.theme + "/ftue_arrow_up.png"
                        //   :
                        "../" + config.theme + "/ftue_arrow.png"
                      }
                    />
                    <TutorialBox />
                    <TutorialInfo
                      style={{
                        minWidth:
                          window.appHeight() / window.innerWidth >
                          config.verticalSizeiphone
                            ? "182%"
                            : window.appHeight() > window.innerWidth
                            ? "191%"
                            : window.innerWidth / window.appHeight() > 2.15
                            ? "134%"
                            : "134%",
                        left:
                          window.appHeight() / window.innerWidth >
                          config.verticalSizeiphone
                            ? "0%"
                            : "",
                      }}
                    >
                      <h1>{t("FTUE shop title")}</h1>
                      <p>{t("FTUE shop info")}</p>
                    </TutorialInfo>
                  </>
                ) : (
                  ""
                )}
              </RankingBox>

              <MissionBox
                onClick={() => {
                  try {
                    socket.emit(
                      "getbilog",
                      {
                        biname: "mission_open",
                      },
                      (data) => {
                        console.log(data);
                      }
                    );
                  } catch (error) {
                    console.log(error);
                  }
                  // setMission(true)
                  // TutorialClick(1)
                  dispatch(actionPopup({ mission: true }));
                  setClick({ ...click, mission: true });
                  setTimeout(() => {
                    setClick({ ...click, mission: false });
                  }, 333);
                  dispatch(actionClickSound());
                }}
              >
                <RedDot
                  active={profile.notimission === "T" ? true : false}
                  style={{ top: "12%", right: "17.5%" }}
                />
                {/* {profileIndex.isguest == false ? (
                  <EventTag>
                    <span>Event</span>
                  </EventTag>
                ) : (
                  ""
                )} */}

                {
                  // profile.isguest == false &&
                  missionEvent > 0 ? (
                    <EventTag>
                      <span>{t("event noti")}</span>
                    </EventTag>
                  ) : (
                    ""
                  )
                }

                <Text>{t("mission")}</Text>
                {profile.tutorial === 1 ||
                profile.tutorial === 3 ||
                profile.tutorial === 5 ||
                profile.tutorial === 7 ||
                profile.tutorial === 9 ||
                profile.tutorial === 11 ||
                profile.tutorial === 13 ||
                profile.tutorial === 15 ? (
                  <>
                    <TutorialArrow
                      src={
                        // window.appHeight() > window.innerWidth
                        //   ? "../" + config.theme + "/ftue_arrow_up.png"
                        //   :
                        "../" + config.theme + "/ftue_arrow.png"
                      }
                    />
                    <TutorialBox />
                    <TutorialInfo
                      style={{
                        minWidth:
                          window.appHeight() / window.innerWidth >
                          config.verticalSizeiphone
                            ? "176%"
                            : window.appHeight() > window.innerWidth
                            ? "218%"
                            : window.innerWidth / window.appHeight() > 2.15
                            ? "134%"
                            : "134%",
                      }}
                    >
                      <h1>
                        {profile.tutorial === 1
                          ? t("FTUE do mission title")
                          : t("FTUE get reward title")}
                      </h1>
                      <p>
                        {profile.tutorial === 1
                          ? t("FTUE do mission info")
                          : t("FTUE get reward info")}
                      </p>
                    </TutorialInfo>
                  </>
                ) : (
                  ""
                )}
              </MissionBox>

              <MinigameBox
                onClick={() => {
                  dispatch(actionPopup({ miniGame: true }));
                  setClick({ ...click, treasure: true });
                  setTimeout(() => {
                    setClick({ ...click, treasure: false });
                  }, 333);
                  dispatch(actionClickSound());
                }}
              >
                <RedDot
                  active={profile.treasureticket > 0}
                  style={{ top: "12%", right: "8%" }}
                />
                <Text>{t("mini game")}</Text>
                {profile.tutorial === 6 ? (
                  <>
                    <TutorialArrow
                      src={
                        // window.appHeight() > window.innerWidth
                        //   ? "../" + config.theme + "/ftue_arrow_up.png"
                        //   :
                        "../" + config.theme + "/ftue_arrow.png"
                      }
                    />
                    <TutorialBox />
                    <TutorialInfo
                      style={{
                        minWidth:
                          window.appHeight() / window.innerWidth >
                          config.verticalSizeiphone
                            ? "159%"
                            : window.appHeight() > window.innerWidth
                            ? "191%"
                            : window.innerWidth / window.appHeight() > 2.15
                            ? "134%"
                            : "134%",
                      }}
                    >
                      <h1>{t("FTUE minigame title")}</h1>
                      <p>{t("FTUE minigame info")}</p>
                    </TutorialInfo>
                  </>
                ) : (
                  ""
                )}
              </MinigameBox>

              <DailyBox
                style={{
                  pointerEvents:
                    window.appHeight() > window.innerWidth ? "none" : "auto",
                  visibility:
                    window.appHeight() > window.innerWidth
                      ? "hidden"
                      : "visible",
                }}
                onClick={() => {
                  try {
                    socket.emit(
                      "getbilog",
                      {
                        biname: "dailyreward_open",
                      },
                      (data) => {
                        console.log(data);
                      }
                    );
                  } catch (error) {
                    console.log(error);
                  }
                  dispatch(actionClickSound());
                  setDailyReward(true);
                }}
              >
                <RedDot
                  active={
                    profile.getreward1 === "F" ||
                    profile.getreward2 === "F" ||
                    profile.getreward3 === "F"
                      ? true
                      : false
                  }
                  style={{
                    top:
                      window.appHeight() / window.innerWidth >
                      config.verticalSizeiphone
                        ? "-23%"
                        : window.appHeight() > window.innerWidth
                        ? "4%"
                        : window.appHeight() / window.innerWidth >
                          config.verticalSizeipad
                        ? "21%"
                        : "14%",
                    right:
                      window.appHeight() / window.innerWidth >
                      config.verticalSizeiphone
                        ? "7vw"
                        : window.appHeight() > window.innerWidth
                        ? "44%"
                        : window.appHeight() / window.innerWidth >
                          config.verticalSizeipad
                        ? "24%"
                        : "13%",
                  }}
                />
              </DailyBox>

              <PromoBox
                onClick={() => {
                  dispatch(actionClickSound());
                  dispatch(actionPopup({ promotion: true }));
                  setClick({ ...click, promo: true });
                  setTimeout(() => {
                    setClick({ ...click, promo: false });
                  }, 333);
                }}
              >
                <RedDot
                  active={profile.notipromotion === "T" ? true : false}
                  style={{
                    top: "12%",
                    right:
                      window.appHeight() / window.innerWidth >
                      config.verticalSizeiphone
                        ? "18%"
                        : window.appHeight() > window.innerWidth
                        ? "-6%"
                        : window.appHeight() / window.innerWidth >
                          config.verticalSizeipad
                        ? "9%"
                        : "9%",
                  }}
                />
                <Text>{t("promotions")}</Text>
              </PromoBox>

              <MailBox
                onClick={
                  profile.isguest && profile.isTelGuest === false
                    ? guestClick
                    : () => {
                        dispatch(actionClickSound());
                        // setEmail(true)
                        dispatch(actionPopup({ email: true }));
                        setClick({ ...click, mail: true });
                        setTimeout(() => {
                          setClick({ ...click, mail: false });
                        }, 333);
                      }
                }
              >
                <RedDot
                  active={profile.notiemail === "T" ? true : false}
                  style={{ top: "12%", right: "17.5%" }}
                />
                <Text>{t("inbox")}</Text>
                {profile.tutorial === 4 ? (
                  <>
                    <TutorialArrow
                      src={
                        // window.appHeight() > window.innerWidth
                        //   ? "../" + config.theme + "/ftue_arrow_up.png"
                        //   :
                        "../" + config.theme + "/ftue_arrow.png"
                      }
                    />
                    <TutorialBox />
                    <TutorialInfo
                      style={{
                        minWidth:
                          window.appHeight() / window.innerWidth >
                          config.verticalSizeiphone
                            ? "159%"
                            : window.appHeight() > window.innerWidth
                            ? "191%"
                            : window.innerWidth / window.appHeight() > 2.15
                            ? "134%"
                            : "134%",
                      }}
                    >
                      <h1>{t("FTUE inbox title")}</h1>
                      <p>{t("FTUE inbox info")}</p>
                    </TutorialInfo>
                  </>
                ) : (
                  ""
                )}
              </MailBox>

              <SupBox
                onClick={() => {
                  try {
                    socket.emit("getSupport", {}, (body) => {
                      if (body.status) {
                        console.log(body);
                        console.log("getSupport Success");
                      } else {
                        console.log("getSupport Error");
                      }
                    });
                  } catch (err) {
                    console.log("getSupport Error");
                  }
                  // dispatch(actionClickSound());
                  setRanking(false);
                  setClick({ ...click, ranking: true });
                  setTimeout(() => {
                    setClick({ ...click, ranking: false });
                  }, 333);
                }}
              >
                <RedDot active={false} style={{ top: "12%", right: "10%" }} />
                <Text>{t("rank")}</Text>
              </SupBox>
            </Box>
            <AnimsBox
            // style={{
            //   bottom:
            //     window.appHeight() / window.innerWidth >
            //     config.verticalSizeiphone
            //       ? `calc(${window.appHeight() * 0.99}px - 31.6vw)`
            //       : window.appHeight() > window.innerWidth
            //       ? `calc(${window.appHeight() * 1.05}px - 31.6vw)`
            //       : "",
            // }}
            >
              <Shop>
                <ShopImg />
                {/* <Shop_sign className="Shop_sign" />
                <Shop_roof />
                <Shop_house />
                <Shop_bush />
                <Shop_bush2 /> */}
              </Shop>
              <MissionAnims>
                <MissionImg />
                {/* <FlagContainer>
                  <Mission_stick />
                  <Mission_flag />
                </FlagContainer> */}
              </MissionAnims>
              <MinigameAnims>
                <MinigameImg />
                {/* <Minigame_controller /> */}
              </MinigameAnims>
              <Daily
                style={{
                  visibility:
                    window.appHeight() > window.innerWidth
                      ? "hidden"
                      : "visible",
                }}
              >
                <WinkContainer>
                  <Wink1 />
                  <Wink2 />
                  <Wink3 />
                  <Wink4 />
                  <Wink5 />
                  <Wink6 />
                </WinkContainer>
                <FlareContainer>
                  <Flare1 />
                </FlareContainer>
                <Daily_sign />
                <Daily_diamond />
                <Daily_logo />
              </Daily>
              <Promotion>
                <PromoImg />
                {/* <Promotion_box />
                <Promotion_ribbon /> */}
              </Promotion>
              <Mail>
                <MailImg />
                {/* <Mail_lid />
                <Mail_main /> */}
              </Mail>
              <Rank>
                <RankIcon>
                  <TextSeason>{profile.season_code}</TextSeason>
                </RankIcon>
                {/* <Rank_bar1 />
                <Rank_bar2 />
                <Rank_bar3 /> */}
              </Rank>
            </AnimsBox>
            <Daily
              style={{
                position: "absolute",
                display:
                  window.appHeight() > window.innerWidth ? "block" : "none",
                marginLeft: "39.65vw",
              }}
            >
              <WinkContainer>
                <Wink1 />
                <Wink2 />
                <Wink3 />
                <Wink4 />
                <Wink5 />
                <Wink6 />
              </WinkContainer>
              <FlareContainer>
                <Flare1 />
              </FlareContainer>
              <Daily_sign />
              <Daily_diamond />
              <Daily_logo />
            </Daily>
            <DailyBox
              style={{
                position: "absolute",
                display:
                  window.appHeight() > window.innerWidth ? "block" : "none",
                left:
                  window.appHeight() / window.innerWidth >
                  config.verticalSizeiphone
                    ? "37vw"
                    : window.appHeight() > window.innerWidth
                    ? "45vw"
                    : "",
                zIndex: 2,
              }}
              onClick={() => {
                try {
                  socket.emit(
                    "getbilog",
                    {
                      biname: "dailyreward_open",
                    },
                    (data) => {
                      console.log(data);
                    }
                  );
                } catch (error) {
                  console.log(error);
                }
                dispatch(actionClickSound());
                setDailyReward(true);
              }}
            >
              <RedDot
                active={
                  profile.getreward1 === "F" ||
                  profile.getreward2 === "F" ||
                  profile.getreward3 === "F"
                    ? true
                    : false
                }
                style={{
                  top:
                    window.appHeight() / window.innerWidth >
                    config.verticalSizeiphone
                      ? "-15%"
                      : window.appHeight() > window.innerWidth
                      ? "4%"
                      : window.appHeight() / window.innerWidth >
                        config.verticalSizeipad
                      ? "21%"
                      : "14%",
                  right:
                    window.appHeight() / window.innerWidth >
                    config.verticalSizeiphone
                      ? "21%"
                      : window.appHeight() > window.innerWidth
                      ? "21%"
                      : window.appHeight() / window.innerWidth >
                        config.verticalSizeipad
                      ? "13%"
                      : "13%",
                }}
              />
            </DailyBox>
          </MiddleFrame>
        </Container>
        {/* <FooterBG click={click} /> */}
      </BG>

      <Transition showPop={treasurebox} type="sb2t">
        <TreasurePop
          closeClick={() => {
            setTreasurebox(false);
          }}
          MainSoundBGM={props.MainSoundBGM}
        />
      </Transition>
      <Transition showPop={popupStatus.avatarLogo} type="st2b">
        <UserProfile
          closeClick={() => {
            dispatch(actionPopup({ avatarLogo: false }));
          }}
        />
      </Transition>
      <Transition
        showPop={
          popupStatus.login ||
          popupStatus.register ||
          popupStatus.forgotPassword
        }
        type="st2b"
      >
        <Login
          closeClick={() => {
            dispatch(actionPopup({ login: false }));
          }}
        />
      </Transition>
      <Transition showPop={popupStatus.guestDetect} type="st2b">
        <GuestDetect
          closeClick={() => {
            dispatch(actionPopup({ guestDetect: false }));
          }}
        />
      </Transition>
      <Transition showPop={popupStatus.deposit} type="st2b">
        <DepositWithdrawal
          closeClick={() => {
            dispatch(actionPopup({ deposit: false }));
          }}
        />
      </Transition>
      <Transition showPop={popupStatus.miniGame} type="sb2t">
        <Minigame
          MainSoundBGM={props.MainSoundBGM}
          closeClick={() => {
            dispatch(actionPopup({ miniGame: false }));
          }}
        />
      </Transition>
      <Transition showPop={popupStatus.promotion} type="sb2t">
        <Promotions
          closeClick={() => {
            dispatch(actionPopup({ promotion: false }));
          }}
        />
      </Transition>
      {/* <Transition showPop={ranking} type="sb2t">
                <Ranking closeClick={() => {
                    setRanking(false)
                }} />
            </Transition> */}
      {/* <Transition showPop={popupStatus.diamondShop} type="sb2t">
        <DiamondExchange
          closeClick={() => {
            // setDiamondexchange(false)
            dispatch(actionPopup({ diamondShop: false }));
            dispatch(
              actionSpinixShopMenu({
                freeSpin: false,
                credit: false,
                deposit: false,
              })
            );
          }}
        />
      </Transition> */}
      <Transition showPop={popupStatus.mission} type="sb2t">
        <Mission
          closeClick={() => {
            // setMission(false)
            dispatch(actionPopup({ mission: false }));
          }}
        />
      </Transition>
      <Transition showPop={popupStatus.event} type="st2b">
        <Event
          closeClick={() => {
            // setMission(false)
            dispatch(actionPopup({ event: false }));
          }}
        />
      </Transition>
      <Transition showPop={dailyReward} type="sb2t">
        <DailyRewardPopup
          closeClick={() => {
            setDailyReward(false);
          }}
        />
      </Transition>
      <Transition showPop={popupStatus.email} type="sb2t">
        <Email
          closeClick={() => {
            dispatch(actionPopup({ email: false }));
            // setEmail(false)
          }}
        />
      </Transition>
      {/* <Transition showPop={support} type="sb2t">
                <Support closeClick={() => {
                    setSupport(false)
                }} />
            </Transition> */}
      <Transition showPop={ranking} type="sb2t">
        <Ranking
          closeClick={() => {
            setRanking(false);
          }}
        />
      </Transition>
      <Transition showPop={emitError.status} type="alert">
        <AlertMessage
          message={t(emitError.message)}
          onClick={() => {
            setEmitError({ status: false });
          }}
        />
      </Transition>
      <Transition showPop={guestPopup} type="alert">
        <GuestPopup
          cancelClick={() => {
            setGuestPopup(false);
          }}
          confirmClick={() => {
            setGuestPopup(false);
          }}
        >
          {t("you have not yet subscribed")}
        </GuestPopup>
      </Transition>
    </>
  );
}
